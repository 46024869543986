import React from 'react';

import ContactImage from '../assets/contact.svg';

type Error = {
  name: boolean;
  email: boolean;
  message: boolean;
};

export interface ContactProps {
  handleContact: (event: React.FormEvent<HTMLFormElement>) => void;
  name: string;
  telephone: string;
  email: string;
  message: string;
  handleChange: (type: string, value: string) => void;
  errors: Error;
  contactError: boolean;
  contactMessage: string;
  isLoading: boolean;
  success: boolean;
  emailLabel: string;
  nameLabel: string;
  messageLabel: string;
  telephoneLabel: string;
  title: string;
  actionLabel: string;
  emailPlaceholder: string;
  namePlaceholder: string;
  telephonePlaceholder: string;
}

export default function Contact({
  handleContact,
  handleChange,
  name,
  email,
  telephone,
  message,
  errors,
  contactError,
  contactMessage,
  isLoading,
  success,
  emailLabel,
  nameLabel,
  messageLabel,
  telephoneLabel,
  title,
  actionLabel,
  emailPlaceholder,
  namePlaceholder,
  telephonePlaceholder,
}: ContactProps) {
  return (
    <div id="contact" className="bg-gray-300 py-10 px-6">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-2 px-12 text-center my-6 leading-tight">
          {title}
        </h2>
        <div className="flex flex-wrap mb-4">
          <div className="md:w-1/2 pt-12 mb-6 md:mb-0">
            <ContactImage className="mx-auto w-full" />
          </div>
          <div className="md:w-1/2 px-10 mx-auto py-12">
            <form
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              onSubmit={handleContact}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  {nameLabel}
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={name}
                    id="name"
                    type="text"
                    placeholder={namePlaceholder}
                    onChange={e => handleChange('name', e.target.value)}
                  />
                  {errors && errors.name && (
                    <p className="text-red-500 text-xs italic">
                      {contactMessage}
                    </p>
                  )}
                </label>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  {emailLabel}
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={email}
                    id="email"
                    type="email"
                    placeholder={emailPlaceholder}
                    onChange={e => handleChange('email', e.target.value)}
                  />
                  {errors && errors.email && (
                    <p className="text-red-500 text-xs italic">
                      {contactMessage}
                    </p>
                  )}
                </label>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  {telephoneLabel}
                  <input
                    value={telephone}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="text"
                    placeholder={telephonePlaceholder}
                    onChange={e => handleChange('telephone', e.target.value)}
                  />
                </label>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  {messageLabel}
                  <textarea
                    value={message}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="message"
                    name="message"
                    onChange={e => handleChange('message', e.target.value)}
                  />
                  {errors && errors.message && (
                    <p className="text-red-500 text-xs italic">
                      {contactMessage}
                    </p>
                  )}
                </label>
              </div>
              <div className="text-center mb-8">
                {contactError && (
                  <p className="text-md text-red-500">{contactMessage}</p>
                )}

                {success && (
                  <p className="text-md text-green-500">{contactMessage}</p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-indigo-900 hover:bg-indigo-700 font-bold rounded-full py-4 px-8 shadow-lg uppercase text-white border-indigo-300 mx-auto"
                  disabled={isLoading}
                >
                  {actionLabel}
                </button>
              </div>
            </form>
            <p className="text-center text-gray-500 text-xs">
              &copy;2019 Symmetrics Lab. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
