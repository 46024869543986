import React from 'react';

import ClimoLogo from '../assets/climo.svg';
import EFLogo from '../assets/engiefactory.svg';
import DendaLogo from '../assets/denda.svg';
import InnspiralLogo from '../assets/innspiral.svg';

type ClientsTypes = {
  title: string;
};

export default function Clients({ title }: ClientsTypes) {
  return (
    <div id="clients" className="py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-black mb-2 px-12 text-center my-6 leading-tight">
          {title}
        </h2>
        <div className="flex flex-wrap md:mb-4">
          <div className="md:w-1/4 text-center p-6 mx-auto my-auto">
            <InnspiralLogo className="mx-auto" />
          </div>
          <div className="md:w-1/4 text-center p-6 mx-auto my-auto">
            <DendaLogo className="mx-auto" />
          </div>
          <div className="md:w-1/4 text-center p-6 mx-auto my-auto">
            <ClimoLogo className="mx-auto" />
          </div>
          <div className="md:w-1/4 text-center p-6 mx-auto my-auto">
            <EFLogo className="mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}
