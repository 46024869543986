import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

import MainImage from '../assets/hero2.svg';

type HeroProps = {
  title: string;
  subtitle: string;
  action: string;
};

export default function Hero({ title, subtitle, action }: HeroProps) {
  return (
    <div className="mt-2 mb-20 md:mt-20">
      <div className="container mx-auto px-6 lg:px-6 md:px-6">
        <MainImage className="mx-auto w-full -my-6 md:my-0" />

        <h2 className="text-4xl md:text-5xl font-bold mb-2 text-center md:my-6 leading-tight md:px-4 lg:px-12">
          {title}
        </h2>
        <h3 className="text-2xl text-center my-8 sm:px-4">{subtitle}</h3>
        <div className="text-center">
          <Link
            type="button"
            spy
            smooth
            offset={-10}
            duration={600}
            className="bg-indigo-900 hover:bg-indigo-700 font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider text-white border-indigo-300"
            to="contact"
          >
            {action}
          </Link>
        </div>
      </div>
    </div>
  );
}
