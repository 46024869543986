import { ThemeProvider } from 'emotion-theming';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { theme } from '../utils/theme';

import NavBar from './NavbarEs';

import '../utils/global.css';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          languageCode
          countryCode
        }
      }
    }
  `);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Helmet
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          defaultTitle={data.site.siteMetadata.title}
        >
          <html lang="es" />
          <meta
            name="description"
            content="Compañía de Software enfocada en el desarrollo de aplicaciones IoT, móviles y web"
          />

          <meta property="og:locale" content="es_ES" />
        </Helmet>
        <NavBar />
        <header>{/* TODO */}</header>

        <main>{children}</main>

        <footer>{/* TODO */}</footer>
      </ThemeProvider>
    </React.StrictMode>
  );
}
