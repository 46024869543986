import React from 'react';

import SIcon from '../assets/s.svg';
import PlantLeft from '../assets/plantleft.svg';
import PlantRight from '../assets/plantright.svg';

export default function Footer() {
  return (
    <div className="bg-gray-300 px-6 pb-6 md:pb-0">
      <div className="container mx-auto">
        <div className="flex">
          <div className="hidden md:block md:w-1/3">
            <PlantLeft className="object-left w-full" />
          </div>
          <div className="w-full bg-gray-300 text-center md:w-1/3 md:pt-12">
            <a
              className="md:text-xl text-center"
              href="mailto:info@symmetricslab.com"
            >
              <SIcon className="md:float-left md:ml-6 md:mr-2 mx-auto" />
              info@symmetricslab.com
            </a>
          </div>
          <div className="hidden md:block md:w-1/3">
            <PlantRight className="float-right" />
          </div>
        </div>
      </div>
    </div>
  );
}
