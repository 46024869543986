import React, { useState, FormEvent } from 'react';

import Layout from '../components/LayoutEs';
import Hero from '../components/Hero';
import Goals from '../components/Goals';
import ServicesEs from '../components/ServicesEs';
import Clients from '../components/Clients';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

export default function IndexPage() {
  const [nombre, setName] = useState('');
  const [correo, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [mensaje, setMessage] = useState('');
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactMessage, setContactMessage] = useState('');

  type Data = {
    name: string;
    email: string;
    message: string;
    telephone: string;
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setMessage('');
    setTelephone('');
    setErrors({
      name: false,
      email: false,
      message: false,
    });
    setContactError(false);
  };

  const validateForm = (data: Data) => {
    const { name, email, message } = data;
    let contactMes = '';
    let valid = true;
    const error = { name: false, email: false, message: false };
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!name) {
      error.name = true;
      contactMes = 'Nombre, email y mensaje son requeridos';
      valid = false;
    }

    if (!email) {
      error.email = true;
      contactMes = 'Nombre, email y mensaje son requeridos';
      valid = false;
    }

    if (!message) {
      error.message = true;
      contactMes = 'Nombre, email y mensaje son requeridos';
      valid = false;
    }

    if (email && !re.test(email)) {
      error.email = true;
      contactMes = 'Por favor, verifica tu dirección de correo';
      valid = false;
    }

    setErrors(error);
    setContactMessage(contactMes);

    console.log(contactMes);

    return valid;
  };

  const handleContact = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    const data = {
      name: nombre,
      email: correo,
      telephone,
      message: mensaje,
    };

    if (!validateForm(data)) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        'https://xo0avwgnya.execute-api.us-east-1.amazonaws.com/dev/mail',
        {
          method: 'post',
          body: JSON.stringify(data),
        },
      );
      if (!response.ok) {
        setLoading(false);
        setContactError(true);
        setContactMessage('Ha ocurrido un error por favor verifica tus datos');
      } else {
        setLoading(false);
        setContactError(false);
        setSuccess(true);
        setContactMessage(
          'Gracias por enviarnos tus datos en breve nos pondremos en contacto.',
        );
        handleReset();
      }
    } catch (error) {
      setLoading(false);
      setContactError(true);
      setContactMessage('Ha ocurrido un error por favor verifica tus datos');
    }
  };

  const handleChange = (type: string, value: string) => {
    if (type === 'name') {
      setName(value);
    }
    if (type === 'email') {
      setEmail(value);
    }
    if (type === 'telephone') {
      setTelephone(value);
    }
    if (type === 'message') {
      setMessage(value);
    }
  };

  return (
    <Layout>
      <Hero
        title="Creamos valor para tu negocio a través de soluciones tecnológicas de alto impacto."
        subtitle="Enfócate en tu negocio, enfócate en tu visión, nosotros la hacemos realidad."
        action="¿En qué te podemos ayudar?"
      />
      <Goals
        title="Nuestro principal objetivo es dar valor a tu negocio."
        description="Symmetrics es una empresa dedicada al desarrollo y creación de
        soluciones tecnológicas. Creemos que la tecnología es una
        herramienta que utilizada correctamente puede llevar cualquier
        empresa al próximo nivel."
      />
      <ServicesEs />
      <Clients title="Nuestros Clientes" />
      <Contact
        handleContact={handleContact}
        name={nombre}
        email={correo}
        telephone={telephone}
        message={mensaje}
        handleChange={handleChange}
        errors={errors}
        contactError={contactError}
        contactMessage={contactMessage}
        isLoading={isLoading}
        success={success}
        title="En qué podemos ayudarte"
        nameLabel="Nombre"
        emailLabel="Email"
        messageLabel="Mensaje"
        telephoneLabel="Teléfono"
        actionLabel="Enviar mensaje"
        namePlaceholder="Tu nombre"
        emailPlaceholder="Email"
        telephonePlaceholder="+56 XXXXXXXXX"
      />
      <Footer />
    </Layout>
  );
}
