import React from 'react';

import SoftwareIcon from '../assets/software.svg';
import IotIcon from '../assets/iot.svg';
import BusinessIcon from '../assets/business.svg';
import MobileIcon from '../assets/mobile.svg';
import UxIcon from '../assets/ux.svg';
import TecIcon from '../assets/tec.svg';

import BoxServices from './BoxServices';

export default function Services() {
  return (
    <div id="services" className="container mx-auto px-6 mt-12">
      <div className="flex flex-wrap mb-4">
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="Software personalizado"
            text="Creamos software a la medida, aplicaciones móviles, web y de
            escritorio. Hacemos que las aplicaciones den valor al negocio y a
            los clientes y sean útiles para alcanzar los objetivos que
            necesitas."
            icon={<SoftwareIcon className="mx-auto" />}
          />
        </div>
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="Plataformas IoT"
            text="Hacemos la conexión entre el mundo físico y el mundo digital a través de IoT. Si un dispositivo se conecta a Internet podemos integrarlo a cualquier plataforma."
            icon={<IotIcon className="mx-auto" />}
          />
        </div>
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="Business Intelligence"
            text="Construimos plataformas para manejar gran volumen de datos. Le damos sentido y valor a la data de tu empresa."
            icon={<BusinessIcon className="mx-auto" />}
          />
        </div>

        <div className="flex flex-wrap mb-4">
          <div className="md:w-1/3 text-center p-6">
            <BoxServices
              title="Aplicaciones Móviles"
              text="Desarrollamos aplicaciones móviles a tu medida, entendiendo tus necesidades y proponiendo soluciones adecuadas que sirvan como canal de comunicación interactiva."
              icon={<MobileIcon className="mx-auto" />}
            />
          </div>
          <div className="md:w-1/3 text-center p-6">
            <BoxServices
              title="Diseño UX / UI"
              text="Creamos experiencias de usuarios desde la investigación, la ideación, el diseño visual, la construcción de prototipos, la implementación y la evaluación."
              icon={<UxIcon className="mx-auto" />}
            />
          </div>
          <div className="md:w-1/3 text-center p-6">
            <BoxServices
              title="Asesoría Tecnológica"
              text="Si quieres evaluar una solución o un equipo de tecnología somos tu aliado. Con la experiencia que tenemos evaluando cientos de soluciones y equipos de tecnología podemos ayudarte a tomar la mejor decisión según los estándares y mejores prácticas de hoy."
              icon={<TecIcon className="mx-auto" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
